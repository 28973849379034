const REGISTRATION_START_FORM_SEND_EVENT_NAME = "registration_form_send"
const REGISTRATION_SMS_CONFIRMATION_EVENT_NAME = "registration_smsConfirmation"
const REGISTRATION_SUCCESS_EVENT_NAME = "registration_success"
const WL_THREE_MONTHS_GIFT_EVENT_NAME = "wlThreeMonthsGift_send"
const WL_APPLICATION_ORDER_EVENT_NAME = "wl_application_order"
const QRM_FREE_PRO_TARIFF_EVENT_NAME = "qrm_freeTariffPro_order"
const CONSUlT_SEND_EVENT_NAME = "consult_send"
const SIGNUP_FORM_CHANGE_EVENT_NAME = "signup_form_change"
const START_TRIAL_SEND_CODE_EVENT_NAME = "start_trial_send_sms_code"
const NEW_SUBSCRIBER_EVENT_NAME = "new_subscriber"
/**
 * Событие на успешную отправку формы "Подписаться"
 */

export function newSubscriberFormSendEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: NEW_SUBSCRIBER_EVENT_NAME })
	}
}

/**
 * Событие на успешную отправку формы "Заказать звонок"
 */
export function consultFormSendEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: CONSUlT_SEND_EVENT_NAME })
	}
}

/**
 * Событие на изменение полей формы регистрации
 */
export function signupFormChangeSendEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: SIGNUP_FORM_CHANGE_EVENT_NAME })
	}
}

/**
 * Событие на клик кнопки отправки формы регистрации
 */
export function startTrialSendCodeEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: START_TRIAL_SEND_CODE_EVENT_NAME })
	}
}

/**
 * Событие на успешную отправку стартовой формы регистрации
 */
export function registrationStartFormSendEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: REGISTRATION_START_FORM_SEND_EVENT_NAME })
	}
}

/**
 * Событие на подтверждение смс при регистрации
 */
export function registrationSmsConfirmationEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: REGISTRATION_SMS_CONFIRMATION_EVENT_NAME })
	}
}

/**
 * Событие на успешную регистрацию
 */
export function registrationSuccessEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: REGISTRATION_SUCCESS_EVENT_NAME })
	}
}

/**
 * Событие на успешную отправку заявки на приложение WL
 */
export function WLApplicationOrderEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: WL_APPLICATION_ORDER_EVENT_NAME })
	}
}

/**
 * Событие на отправку "Три месяца подписки в подарок!" для WL
 */
export function wlThreeMonthsGiftEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: WL_THREE_MONTHS_GIFT_EVENT_NAME })
	}
}

/**
 * Событие на отправку "Управляйте бизнесом через смартфон" для QRM
 */
export function qrmFreeTariffProEvent() {
	if (typeof window !== "undefined") {
		window.dataLayer.push({ event: QRM_FREE_PRO_TARIFF_EVENT_NAME })
	}
}
